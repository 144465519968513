import React from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { useAnalytics } from "../hooks"

import {
  GoogleReviews,
  Header,
  Layout,
  SEO,
  SectionsHandler,
  SignOffStillLooking,
  SectionStats,
  TutorCard,
} from "../components"
import { pageType, sectionStats, tutorType } from "../types"

type Props = PageProps & {
  data: pageType & {
    allContentfulTutors: {
      edges: Array<{ node: tutorType }>
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()

  const url = process.env.GATSBY_SITE_URL + "/about/"

  analytics.track(["User", "Page View"], { page: "/about/" })

  const tutors = data.allContentfulTutors.edges
  // @ts-ignore
  const stats: sectionStats | undefined = data.contentfulPages.sections.find(
    (section) => section.__typename === "ContentfulPageSectionsStats"
  )
  return (
    <Layout>
      <SEO
        title={data.contentfulPages.seo.title}
        description={data.contentfulPages.seo.description.description}
        image={data.contentfulPages.seo.socialImage?.file?.url}
        url={url}
      />
      <Header
        heading={data.contentfulPages.heading}
        headerImage={null}
        headerIntroduction={data.contentfulPages.headerIntroduction}
      >
        <GatsbyImage
          className="aboutHeaderImage"
          image={data.contentfulPages.headerImage.gatsbyImageData}
          alt={data.contentfulPages.headerImage.title}
        />
        {stats && <SectionStats stats={stats} />}
      </Header>
      <main>
        <section className="backgroundGreyLightSuper">
          <GoogleReviews variant="about" />
          <div className="wrapperWidth wrapperHeight--small aboutTutors">
            <h2 className="headingSmall aboutTutorsHeading">Meet the tutors</h2>
            <div className="aboutTutorsList">
              {tutors.map(({ node }) => (
                <TutorCard
                  key={node.contentful_id}
                  contentful_id={node.contentful_id}
                  name={node.name}
                  nameShort={node.nameShort}
                  slug={node.slug}
                  photo={node.photo}
                  summary={node.summary}
                  about={node.about}
                  twitterProfile={node.twitterProfile}
                  linkedInProfile={node.linkedInProfile}
                  instagramProfile={node.instagramProfile}
                  seo={node.seo}
                  variant="about"
                />
              ))}
            </div>
          </div>
        </section>
        <SectionsHandler sections={data.contentfulPages.sections} />
        <SignOffStillLooking />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query getAboutPageContent {
    contentfulPages(page: { eq: "About" }) {
      contentful_id
      heading
      headerIntroduction {
        raw
      }
      headerImage {
        gatsbyImageData
        title
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
      sections {
        ... on ContentfulPageSectionsCourseGroups {
          contentful_id
          name
          __typename
          courseGroups {
            ... on ContentfulCourseGroups {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                title
                gatsbyImageData
              }
              contentful_id
              slug
            }
            ... on ContentfulCourseTopics {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                gatsbyImageData
                title
              }
              contentful_id
              slug
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsFaQs {
          __typename
          contentful_id
          heading
          faQs {
            heading
            content {
              raw
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsHowItWorks {
          __typename
          contentful_id
          heading
          steps {
            contentful_id
            description {
              description
            }
            heading
            icon {
              file {
                url
              }
              gatsbyImageData
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStats {
          __typename
          contentful_id
          stats {
            name
            description {
              description
            }
            number
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsTextWithImage {
          __typename
          id
          contentful_id
          image {
            gatsbyImageData
            title
            url
          }
          video {
            url
            title
          }
          imageAlignment
          heading
          content {
            raw
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStrap {
          __typename
          id
          contentful_id
          buttonSupportingText
          description
          name
          buttonText
          buttonUrl
          backgroundColour
        }
      }
    }
    allContentfulTutors(sort: { order: ASC, fields: name }) {
      edges {
        node {
          contentful_id
          achievements
          twitterProfile
          qualifications
          linkedInProfile
          instagramProfile
          nameShort
          name
          about {
            raw
          }
          summary {
            summary
          }
          slug
          photo {
            gatsbyImageData
            title
          }
          seo {
            socialImage {
              file {
                url
              }
            }
            description {
              description
            }
            title
          }
        }
      }
    }
  }
`
